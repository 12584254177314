import { CATEGORIES, TAGS } from "./filters";

export const videoList = [
  {
    localUrl: "showreel23",
    vimeoUrl: "https://player.vimeo.com/video/935381658?h=c3165f4740",
    category: CATEGORIES.showreel,
    tags: [TAGS.showreel],
    label: "Шоурил 2023",
    poster: "showreel23-poster.jpg",
  },
  {
    localUrl: "waygroup",
    vimeoUrl: "https://player.vimeo.com/video/935394734?h=10f62926bd",
    category: CATEGORIES.reclama,
    tags: [TAGS.digital, TAGS.promo, TAGS.korp],
    label: "Way Group",
    poster: "waygroup-poster.jpg",
  },
  {
    localUrl: "funkytoys",
    vimeoUrl: "https://player.vimeo.com/video/913785752?h=8d20c75124",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.tv, TAGS.digital],
    label: "Funky Toys",
    poster: "funkytoys-poster.jpg",
  },
  {
    localUrl: "akbars",
    vimeoUrl: "https://player.vimeo.com/video/913786109?h=c8dc724ed9",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.digital],
    label: "Ак Барс",
    poster: "akbars-poster.jpg",
  },
  // {
  //   localUrl: "vkid",
  //   vimeoUrl: "",
  //   category: CATEGORIES.reclama,
  //   tags: [TAGS.promo, TAGS.digital],
  //   label: "VK ID",
  //   poster: "vkid-poster.jpg",
  // },

  {
    localUrl: "veren",
    vimeoUrl: "https://player.vimeo.com/video/986284793?h=44537b6336",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital],
    label: "Veren group / Promo",
    poster: "veren-poster.jpg",
  },

  {
    localUrl: "softlineeducation",
    vimeoUrl: "https://player.vimeo.com/video/913786160?h=f4db00464f",
    category: CATEGORIES.korpVideo,
    tags: [TAGS.korp],
    label: "Softline Образование",
    poster: "softlineeducation-poster.jpg",
  },

  //old
  {
    localUrl: "showreel",
    vimeoUrl: "https://player.vimeo.com/video/687465086?h=100169ac7e",
    category: CATEGORIES.showreel,
    tags: [TAGS.showreel],
    label: "SHOWREEL 2021",
    poster: "showreel-port-poster.jpg",
  },
  {
    localUrl: "cg",
    vimeoUrl: "https://player.vimeo.com/video/788918951?h=7d993bb16e",
    category: CATEGORIES.showreel,
    tags: [TAGS.showreel],
    label: "GRAPHICS SHOWREEL 2022",
    poster: "cg-poster.jpg",
  },
  {
    localUrl: "fpv-showreel",
    vimeoUrl: "https://player.vimeo.com/video/759451849?h=813dd51b71",
    category: CATEGORIES.showreel,
    tags: [TAGS.showreel],
    label: "FPV SHOWREEL 2021",
    poster: "fpv-showreel-poster.jpeg",
  },
  // {
  //   localUrl: "polemica",
  //   vimeoUrl: "https://player.vimeo.com/video/739490263?h=09ec3740bb",
  //   category: CATEGORIES.reclama,
  //   tags: [TAGS.promo, TAGS.digital],
  //   label: "Polemica / Promo video",
  //   poster: "polemica-poster.jpg",
  // },
  {
    localUrl: "vtb",
    vimeoUrl:
      "https://player.vimeo.com/video/966460214?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital, TAGS.olv],
    label: "ВТБ",
    poster: "vtb-poster.jpg",
  },
  // {
  //   localUrl: "tingz",
  //   vimeoUrl: "https://player.vimeo.com/video/787493426?h=36c3c825fd",
  //   category: CATEGORIES.reclama,
  //   tags: [TAGS.promo, TAGS.digital],
  //   label: "Tingz / Promo video",
  //   poster: "tingz-poster.jpg",
  // },
  {
    localUrl: "izum",
    vimeoUrl: "https://player.vimeo.com/video/528187039?h=e8e420bfc5",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.tv],
    label: "Izum / TV Promo",
    poster: "izum-poster.jpg",
  },
  {
    localUrl: "ulibka",
    vimeoUrl: "https://player.vimeo.com/video/788918319?h=ce8a5dc86e",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.tv],
    label: "Ulybka / TV Ad",
    poster: "ulibka-poster.jpg",
  },
  {
    localUrl: "sushi",
    vimeoUrl: "https://player.vimeo.com/video/528189934?h=b0d1b705ef",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital, TAGS.food],
    label: "Sushi-Market / Food video",
    poster: "sushi-poster.jpg",
  },
  {
    localUrl: "moretv",
    vimeoUrl: "https://player.vimeo.com/video/794151930?h=d6d0e713f5",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital],
    label: "More.tv / Teaser",
    poster: "moretv-poster.jpg",
  },

  {
    localUrl: "linkin",
    vimeoUrl: "https://player.vimeo.com/video/526066864?h=385e933002",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital],
    label: "Linkin.Park / Promo",
    poster: "linkin-poster.jpg",
  },
  {
    localUrl: "eurasia",
    vimeoUrl: "https://player.vimeo.com/video/759451596?h=377a4bd074",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.tv, TAGS.food],
    label: "Eurasia Bar / Food",
    poster: "eurasia-poster.jpg",
  },
  {
    localUrl: "rusich",
    vimeoUrl: "https://player.vimeo.com/video/528193680?h=dd753025e0",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital],
    label: "Rusich / New Collection",
    poster: "rusich-poster.jpg",
  },
  {
    localUrl: "lavash",
    vimeoUrl: "https://player.vimeo.com/video/526077604?h=9197b41ff0",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital, TAGS.threeD, TAGS.CG],
    label: "Lavash / Promo",
    poster: "lavash-poster.jpg",
  },
  {
    localUrl: "almaz",
    vimeoUrl: "https://player.vimeo.com/video/473069700?h=acedeb1c97",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.tv],
    label: "Almazny Zal / TV Promo",
    poster: "almaz-poster.jpg",
  },
  {
    localUrl: "dom",
    vimeoUrl: "https://player.vimeo.com/video/759499744?h=55e5aefc8d",
    category: CATEGORIES.docKino,
    tags: [TAGS.docKino, TAGS.aero, TAGS.FPV],
    label: "Там, где наш дом / Трейлер",
    poster: "dom-poster.jpg",
  },
  {
    localUrl: "olymp",
    vimeoUrl: "https://player.vimeo.com/video/831151211?h=c23d542973",
    category: CATEGORIES.docKino,
    tags: [TAGS.docKino, TAGS.aero, TAGS.FPV],
    label: "Улицы Олимпийского резерва / Трейлер",
    poster: "olymp-poster.jpg",
  },
  {
    localUrl: "poezd",
    vimeoUrl: "https://player.vimeo.com/video/831154612?h=b8c89f077c",
    category: CATEGORIES.docKino,
    tags: [TAGS.docKino, TAGS.aero, TAGS.FPV],
    label: "Нескорый поезд / Трейлер",
    poster: "poezd-poster.jpg",
  },
  {
    localUrl: "nauka",
    vimeoUrl: "https://player.vimeo.com/video/687466644?h=f3c4ff2a6d",
    category: CATEGORIES.docKino,
    tags: [TAGS.docKino, TAGS.threeD, TAGS.CG],
    label: "Займемся наукой. Биоинженерия / Трейлер",
    poster: "nauka-poster.jpg",
  },
  {
    localUrl: "nezav",
    vimeoUrl: "https://player.vimeo.com/video/788917697?h=3077e2e530",
    category: CATEGORIES.docKino,
    tags: [TAGS.docKino, TAGS.aero, TAGS.FPV, TAGS.threeD, TAGS.CG],
    label: "Игровая независимость / Трейлер",
    poster: "nezav-poster.jpg",
  },
  // {
  //   localUrl: "gayane",
  //   vimeoUrl: "https://player.vimeo.com/video/519605332?h=7dbd2602fc",
  //   category: CATEGORIES.docReclama,
  //   tags: [TAGS.docKino, TAGS.digital, TAGS.korp],
  //   label: "Yandex Go - Gayane / Doc commercial",
  //   poster: "gayane-poster.jpg",
  // },
  {
    localUrl: "sports",
    vimeoUrl:
      "https://player.vimeo.com/video/1023951929?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital, TAGS.olv],
    label: 'Прожектор Трибуны от Спортса"',
    poster: "sports-poster.jpg",
  },
  {
    localUrl: "victor",
    vimeoUrl: "https://player.vimeo.com/video/433120966?h=58c9a7cf30",
    category: CATEGORIES.docReclama,
    tags: [TAGS.docKino, TAGS.digital, TAGS.korp],
    label: "Yandex Go - Victor / Doc commercial",
    poster: "victor-poster.jpg",
  },
  {
    localUrl: "ctrl-cometa",
    vimeoUrl: "https://player.vimeo.com/video/759443253?h=6817228d65",
    category: CATEGORIES.clip,
    tags: [TAGS.clip],
    label: "Ctrl+A / Music video",
    poster: "ctrl-cometa-poster.jpg",
  },
  {
    localUrl: "dose",
    vimeoUrl: "https://player.vimeo.com/video/480792660?h=29aec5929c",
    category: CATEGORIES.clip,
    tags: [TAGS.clip],
    label: "Dose - Потеряли / Music video",
    poster: "dose-poster.jpg",
  },
  {
    localUrl: "ctrl",
    vimeoUrl: "https://player.vimeo.com/video/788917724?h=8cb68bdf9b",
    category: CATEGORIES.clip,
    tags: [TAGS.clip],
    label: "CTRL+A - Add / Music video",
    poster: "ctrl-poster.jpg",
  },
  {
    localUrl: "bums",
    vimeoUrl:
      "https://player.vimeo.com/video/452720835?h=e8f2ecddf6&color=dc9a00",
    category: CATEGORIES.clip,
    tags: [TAGS.clip],
    label: "STILL BUMS / Music video",
    poster: "bums-poster.jpg",
  },
  {
    localUrl: "love",
    vimeoUrl: "https://player.vimeo.com/video/788555596?h=43835812c7",
    category: CATEGORIES.clip,
    tags: [TAGS.clip],
    label: "Random Love / Music video",
    poster: "love-poster.jpg",
  },
  {
    localUrl: "myqeed",
    vimeoUrl: "https://player.vimeo.com/video/794151997?h=6cc32911eb",
    category: CATEGORIES.clip,
    tags: [TAGS.clip],
    label: "MYQEED / Snippet",
    poster: "myqeed-poster.jpg",
  },
  {
    localUrl: "narcissus",
    vimeoUrl: "https://player.vimeo.com/video/831158837?h=97ac7ea687",
    category: CATEGORIES.clip,
    tags: [TAGS.clip],
    label: "The Story of Narcissus / Dance shot",
    poster: "narcissus-poster.jpg",
  },
  {
    localUrl: "oemz",
    vimeoUrl: "https://player.vimeo.com/video/473066006?h=a36868cc09",
    category: CATEGORIES.korpVideo,
    tags: [TAGS.korp],
    label: "OEMZ / Corporate video",
    poster: "oemz-poster.jpg",
  },
  {
    localUrl: "rt",
    vimeoUrl: "https://player.vimeo.com/video/759451797?h=cf795c130a",
    category: CATEGORIES.korpVideo,
    tags: [TAGS.korp, TAGS.aero, TAGS.FPV],
    label: "RT / Corporate video",
    poster: "rt-poster.jpg",
  },
  {
    localUrl: "softline",
    vimeoUrl: "https://player.vimeo.com/video/788922347?h=6291ea4d19",
    category: CATEGORIES.korpVideo,
    tags: [TAGS.korp, TAGS.aero],
    label: "Softline / Corporate video",
    poster: "softline-poster.jpg",
  },
  {
    localUrl: "tool",
    vimeoUrl: "https://player.vimeo.com/video/831173678?h=6d095f3b2",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.digital, TAGS.korp],
    label: "Silbertool / 3D",
    poster: "tool-poster.jpg",
  },
  {
    localUrl: "mega",
    vimeoUrl: "https://player.vimeo.com/video/788917875?h=74e9fd37e9",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.digital],
    label: "МЕГА / Graphics",
    poster: "mega-poster.jpg",
  },
  {
    localUrl: "them",
    vimeoUrl: "https://player.vimeo.com/video/790835890?h=48b77c349a",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.digital],
    label: "THEM / 3D product",
    poster: "them-poster.jpg",
  },
  {
    localUrl: "zemlya",
    vimeoUrl: "https://player.vimeo.com/video/790835952?h=9b525cf818",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.opening],
    label: "Zemlya 2030 / 3D oppening",
    poster: "zemlya-poster.jpg",
  },
  {
    localUrl: "borodina",
    vimeoUrl: "https://player.vimeo.com/video/790835696?h=ff0470ecdd",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.opening],
    label: "Borodina / 3D oppening",
    poster: "borodina-poster.jpg",
  },
  {
    localUrl: "vzyatki",
    vimeoUrl: "https://player.vimeo.com/video/790835923?h=40d143eb38",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.opening],
    label: "Vzyatki / 3D oppening",
    poster: "vzyatki-poster.jpg",
  },
  {
    localUrl: "nft",
    vimeoUrl: "https://player.vimeo.com/video/790838242?h=e6a073fad6",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.digital],
    label: "NFT Collection / 3D",
    poster: "nft-poster.jpg",
  },
  {
    localUrl: "rizolin-city",
    vimeoUrl: "https://player.vimeo.com/video/788918262?h=0d3f42e6d5",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.digital, TAGS.korp],
    label: "Rizolin (city) / 3D",
    poster: "rizolin-city-poster.jpg",
  },
  {
    localUrl: "rizolin",
    vimeoUrl: "https://player.vimeo.com/video/788918146?h=393df4d3ae",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.digital, TAGS.korp],
    label: "Rizolin / 3D",
    poster: "rizolin-poster.jpg",
  },
  {
    localUrl: "cyber",
    vimeoUrl: "https://player.vimeo.com/video/790835858?h=dcea60256f",
    category: CATEGORIES.graph,
    tags: [TAGS.threeD, TAGS.opening],
    label: "Cyber ethics / 3D oppening",
    poster: "cyber-poster.jpg",
  },
  {
    localUrl: "hand",
    vimeoUrl: "https://player.vimeo.com/video/790835806?h=a8c91d8b51",
    category: CATEGORIES.graph,
    tags: [TAGS.CG, TAGS.threeD],
    label: "Cyber hand / 3D",
    poster: "hand-poster.jpg",
  },

  {
    localUrl: "multispace",
    vimeoUrl: "https://player.vimeo.com/video/831162582?h=07b9e0f26d",
    category: CATEGORIES.graph,
    tags: [TAGS.korp],
    label: "Multispace / Infographics",
    poster: "multispace-poster.jpg",
  },
  {
    localUrl: "vokzal",
    vimeoUrl: "https://player.vimeo.com/video/788557268?h=4f77c6281c",
    category: CATEGORIES.FPV,
    tags: [TAGS.promo, TAGS.aero, TAGS.FPV, TAGS.CG],
    label: "VOKZAL 1853 / FPV",
    poster: "vokzal-poster.jpg",
  },
  {
    localUrl: "ciay",
    vimeoUrl: "https://player.vimeo.com/video/794562118?h=b6d3fd91c5",
    category: CATEGORIES.FPV,
    tags: [TAGS.promo, TAGS.aero, TAGS.FPV],
    label: "CIAY MOTOCROSS / Strokov David",
    poster: "ciay-poster.jpg",
  },
  {
    localUrl: "asber",
    vimeoUrl: "https://player.vimeo.com/video/788557227?h=c010f977b1",
    category: CATEGORIES.FPV,
    tags: [TAGS.promo, TAGS.aero, TAGS.FPV, TAGS.CG],
    label: "Asber / FPV",
    poster: "asber-poster.jpg",
  },
  {
    localUrl: "living-display",
    vimeoUrl: "https://player.vimeo.com/video/533567798?h=1d0b860b90",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital, TAGS.threeD],
    label: "Living Skog (Display Storage)",
    poster: "living-display-poster.jpg",
  },
  {
    localUrl: "living-cabinet",
    vimeoUrl: "https://player.vimeo.com/video/533564479?h=7be9544353",
    category: CATEGORIES.reclama,
    tags: [TAGS.promo, TAGS.digital, TAGS.threeD],
    label: "Living Skog (Cabinet)",
    poster: "living-cabinet-poster.jpg",
  },
  {
    localUrl: "baggins",
    vimeoUrl: "https://player.vimeo.com/video/788739813?h=268e0b3bc5",
    category: CATEGORIES.reclama,
    tags: [],
    label: "BAGGINS COFFEE / Promo",
    poster: "baggins-poster.jpg",
  },
  //regular
  {
    localUrl: "bogolubov",
    vimeoUrl: "https://www.youtube.com/embed/cSu1xnhVac0?si=F5dH0lvi6wee7TTS",
    category: CATEGORIES.regular,
    tags: [TAGS.regular],
    label:
      "Дискуссия о выставке / «Алексей Боголюбов. К 200-летию со дня рождения»",
    poster: "bogolubov-poster.jpg",
  },
  {
    localUrl: "vecgraph",
    vimeoUrl: "https://www.youtube.com/embed/A9mrhOirjUU?si=SKwAUi3lpW02Zr8M",
    category: CATEGORIES.regular,
    tags: [TAGS.regular],
    label:
      "Дискуссия о выставке / «Век графики. От Казимира Малевича до Олега Кудряшова. Из новых поступлений»",
    poster: "vecgraph-poster.jpg",
  },
  {
    localUrl: "inmemoriam",
    vimeoUrl: "https://www.youtube.com/embed/pk3eiMJvyXg?si=i7J_G2ac87goVNYX",
    category: CATEGORIES.regular,
    tags: [TAGS.regular],
    label: "Дискуссия о выставке / «In Memoriam»",
    poster: "inmemoriam-poster.jpg",
  },
];
